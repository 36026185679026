//
// Root
//

:root {
	--#{$variable-prefix}card-bg: #{$card-bg};
	--#{$variable-prefix}card-header-bg: #{$card-header-background-color};
	--#{$variable-prefix}card-footer-bg: #{$card-header-background-color};
}

[theme='dark']:root {
	// Body
	--#{$variable-prefix}body-bg: #{$dark-body-bg};
	--#{$variable-prefix}body-color: #{$dark-body-color};
	// Card
	--#{$variable-prefix}card-bg: #{$dark-card-bg};
	--#{$variable-prefix}card-header-bg: #{$dark-card-header-background-color};
	--#{$variable-prefix}card-footer-bg: #{$dark-card-footer-background-color};
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}
