.highlightOption{
    background: $primary !important;
}

.optionContainer li:hover{
    background: $primary !important;
}

.chip{
    background: $primary !important;
}

.searchWrapper{
    width: 100% !important;
    height: 100% !important;
    border: none !important;
}